<template>
  <v-container>
    <v-data-table v-model="selected"
                  height="60vh"
                  :headers="headers[currentReportTable]"
                  :loading="loading"
                  :items="tokens"
                  :page="pagination.page"
                  :footer-props="{
                          'items-per-page-options':[5,10,20],
                          itemsPerPageText:''
                        }"
                  @update:items-per-page="itemsPerPageChange"
                  @update:page="pageChange"
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.rowsPerPage"
                  loading-text="Loading... Please wait"
                  item-key="name"
                  class="elevation-1">
      <template v-if="currentReportTable==='users'" v-slot:item.user.userInfo.nickname="{ item }">
        <router-link :to="'/users/'+item.user.id">
          <v-avatar size="45px" class="ma-2">
            <v-img :src="item.user.userInfo.avatarUrl || require('../../assets/images/users/2.png')"></v-img>
          </v-avatar>{{ item.user.userInfo.nickname }}
        </router-link>
      </template>

      <template v-if="currentReportTable==='users'" v-slot:item.requestedUser.userInfo.nickname="{ item }">
        <router-link :to="'/users/'+item.requestedUser.id">
          <v-avatar size="45px" class="ma-2">
            <v-img :src="item.requestedUser.userInfo.avatarUrl || require('../../assets/images/users/2.png')"></v-img>
          </v-avatar>{{ item.requestedUser.userInfo.nickname }}
        </router-link>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import BaseCard from "../../components/Base/BaseCard.vue";
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";

export default {
  name: "Reports",
  components: {
    BaseCard,
    BaseHeader
  },
  methods: {
    onSelectChange(val) {
      this.fetchReports()
    },
    pageChange(newPage) {
      this.pagination.page = newPage
      this.fetchReports()
    },
    itemsPerPageChange(newPerPage) {
      this.pagination.rowsPerPage = newPerPage
      this.fetchReports()
    },
    setHideNFt(nftItem) {
      this.nftToHide = nftItem
      this.showNftHideConfirm = true
    },
    fetchReports() {
      this.loading = true;
      axios.get(process.env.VUE_APP_BASE_API_URL + '/admin/reports/' + this.currentReportTable, {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.rowsPerPage,
          sort: 'created,desc'
        }
      }).then(resp => {
        this.tokens = resp.data.content
        this.pagination.totalItems = resp.data.totalElements

      }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    this.fetchReports()
  },
  props: {
    currentReportTable: {
      type: String,
      default: () => ('users')
    }
  },
  data() {
    return {
      pagination: {
        descending: true,
        sortBy: 'created',
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
      },
      showNftHideConfirm: false,
      nftToHide: null,
      loading: false,
      tokens: [],
      selected: [],
      headers: {
        'users': [{
          text: 'User',
          align: 'start',
          sortable: false,
          value: 'user.userInfo.nickname',
        }, {
          text: 'Requester',
          align: 'start',
          sortable: false,
          value: 'requestedUser.userInfo.nickname',
        },
          {
            text: 'Theme',
            sortable: false,
            value: 'theme'
          },
        ],
        'nft_tokens': [{
          text: 'raelic',
          align: 'start',
          sortable: false,
          value: 'nftToken.title',
        }, {
          text: 'Requester',
          align: 'start',
          sortable: false,
          value: 'requestedUser.email',
        },
          {
            text: 'Theme',
            sortable: false,
            value: 'theme'
          },
        ],
        'comments': [{
          text: 'From',
          align: 'start',
          sortable: false,
          value: 'comment.user.email',
        }, {
          text: 'Requester',
          align: 'start',
          sortable: false,
          value: 'requestedUser.email',
        },
          {
            text: 'Theme',
            sortable: false,
            value: 'theme'
          },
        ],
        'reply_comments': [{
          text: 'From',
          align: 'start',
          sortable: false,
          value: 'comment.user.email',
        }, {
          text: 'Requester',
          align: 'start',
          sortable: false,
          value: 'requestedUser.email',
        },
          {
            text: 'Theme',
            sortable: false,
            value: 'theme'
          },
        ]
      }
    }
  },
};
</script>
