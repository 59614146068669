<template>
  <v-container>
    <base-header v-if="!byUserIdFollowing && !byUserIdFollowers">
      <div slot="page-header">
        <h2 class="font-weight-black primary--text">Users</h2>
      </div>
      <div slot="page-search">
        <v-card elevation="0">
          <v-card-title v-if="!byUserIdFollowing && !byUserIdFollowers">
            <v-spacer></v-spacer>
            <v-form @submit.prevent="doSearch">
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-form>
          </v-card-title>
        </v-card>
      </div>
    </base-header>
    <v-data-table v-model="selected"
                  @click:row="goToUser"
                  :headers="headers"
                  height="60vh"
                  :page="pagination.page"
                  :footer-props="{
                        'items-per-page-options':[5,10,20],
                        itemsPerPageText:''
                      }"
                  :search="search"
                  @update:items-per-page="itemsPerPageChange"
                  @update:page="pageChange"
                  @update:sort-by="sortChange"
                  @update:sort-desc="sortDescChange"
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.rowsPerPage"
                  :loading="loading"
                  :items="users"
                  loading-text="Loading... Please wait"
                  item-key="id"
                  class="elevation-1"
                  :class="[selected && 'selected']"
    >

      <template v-slot:item.userInfo.avatarUrl="{ item }">
        <v-avatar size="45px" class="ma-2">
          <v-img :src="item.userInfo.avatarUrl || require('../../assets/images/users/2.png')"></v-img>
        </v-avatar>
      </template>

      <template v-slot:item.role="{ item }">
        <v-row align="center" class="px-3">
          <span>{{ item.role }}</span>
        </v-row>
      </template>

      <template v-slot:item.created="{ item }">
        <v-row align="center" class="px-3">
          <span>{{ new Date(item.created).toLocaleString() }}</span>
        </v-row>
      </template>
      <template v-slot:item.status="{ item }">
        <v-row align="center" class="px-3">
          <span>{{ item.status }}</span>
        </v-row>
      </template>
    </v-data-table>
    <v-dialog v-model="showBanUserConfirm" width="300">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to ban {{ userToBan && userToBan.email }}?<br/>
          This user can be restored only manually from backend.
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showBanUserConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="banUser()">
          BAN
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showVerifyUserConfirm" width="300">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to set as verified {{ userToVerify && userToVerify.email }}?
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showVerifyUserConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="verifyUser()">
          VERIFY
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showChangeUserRole" width="300">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Select the new user role for {{ selectedUser.email }}
        </div>

        <v-select
            v-model="selectedUser.role"
            :items="[{id:'ADMIN', label:'Admin'},{id: 'USER', label:'User'}, {id:'MODERATOR', label:'Moderator'}]"
            item-text="label"
            item-value="id"
            label="Role"
        ></v-select>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showChangeUserRole=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="setUserRole()">
          Assign
        </v-btn>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseCard from "../../components/Base/BaseCard.vue";
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";

export default {
  name: 'users',
  components: {
    BaseCard,
    BaseHeader
  },
  methods: {
    doSearch() {
      if (this.search) {
        this.fetchUsers(this.search)
      } else {
        this.fetchUsers()
      }
    },
    goToUser(user) {
      this.$router.push({name: 'User', params: {user: user.id}})
    },
    pageChange(newPage) {
      this.pagination.page = newPage
      this.fetchUsers()
    },
    sortChange(newSort) {
      this.pagination.sortBy = newSort[0]
      this.fetchUsers()
    },
    sortDescChange(newSortDesc) {
      this.pagination.descending = newSortDesc[0]
      this.fetchUsers()
    },
    itemsPerPageChange(newPerPage) {
      this.pagination.rowsPerPage = newPerPage
      this.fetchUsers()
    },
    setBanUser(user) {
      this.userToBan = user
      this.showBanUserConfirm = true
    },
    setVerifyUser(user) {
      this.userToVerify = user
      this.showVerifyUserConfirm = true
    },
    banUser() {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + '/admin/ban_user?userId=' + this.userToBan.id).then(resp => {
        this.fetchUsers()
        this.showBanUserConfirm = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    verifyUser() {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + '/admin/verify_user?userId=' + this.userToVerify.id).then(resp => {
        this.fetchUsers()
        this.showVerifyUserConfirm = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    setUserRole() {
      this.loading = true;
      axios.put(process.env.VUE_APP_BASE_API_URL + `/admin/change_role?userId=${this.selectedUser.id}&role=${this.selectedUser.role}`).then(resp => {
        this.fetchUsers()
        this.showChangeUserRole = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    fetchUsers(searchString = undefined) {
      this.loading = true;
      let url = process.env.VUE_APP_BASE_API_URL + `/admin/user`;

      if (this.byUserIdFollowing) {
        url = process.env.VUE_APP_BASE_API_URL + `/admin/user/${this.byUserIdFollowing}/following`
      }
      if (this.byUserIdFollowers) {
        url = process.env.VUE_APP_BASE_API_URL + `/admin/user/${this.byUserIdFollowers}/followers`
      }

      axios.get(url, {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.rowsPerPage,
          search: searchString,
          sort: `${this.pagination.sortBy || ((this.byUserIdFollowing || this.byUserIdFollowers) ? 'id' : 'userInfo.nickname')},${this.pagination.descending === true ? 'desc' : 'asc'}`
        }
      })
          .then(resp => {
            this.users = resp.data.content
            this.pagination.totalItems = resp.data.totalElements
          }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    this.$watch(
        () => this.byUserIdFollowing,
        (toValue, previousValue) => {
          if (toValue) {
            this.fetchUsers()
          }
        }
    )

    this.$watch(
        () => this.byUserIdFollowers,
        (toValue, previousValue) => {
          if (toValue) {
            this.fetchUsers()
          }
        }
    )
    this.fetchUsers()
  },

  props: {
    byUserIdFollowing: {
      type: String | null,
      default: () => null,
    },
    byUserIdFollowers: {
      type: String | null,
      default: () => null,
    }
  },
  data() {
    let data = {
      currentUserId: this.$store.state.current_user_id,
      userToVerify: false,
      userToBan: false,
      selectedUser: false,
      search: null,
      pagination: {
        descending: false,
        sortBy: null,
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
      },
      showVerifyUserConfirm: false,
      showBanUserConfirm: false,
      showChangeUserRole: false,
      loading: false,
      users: [],
      selected: [],
      headers: null
    };
    data.headers = [
      {
        text: '',
        align: 'start',
        sortable: false,
        width: '80px',
        value: 'userInfo.avatarUrl',
      }, {
        text: 'Username',
        align: 'start',
        sortable: !this.byUserIdFollowers && !this.byUserIdFollowing,
        value: 'userInfo.nickname',
      }, {
        text: 'Email',
        sortable: true,
        value: 'email'
      }, {
        text: 'Created on',
        sortable: !this.byUserIdFollowers && !this.byUserIdFollowing,
        value: 'created'
      }, {
        text: 'Status',
        sortable: !this.byUserIdFollowers && !this.byUserIdFollowing,
        value: 'status'
      }, {
        text: '# of wallets',
        sortable: false,
        value: 'walletsCount'
      }, {
        text: 'Role',
        sortable: true,
        value: 'role'
      },
    ]
    return data;
  },
};
</script>
<style scoped>
.selected {
  cursor: pointer;
}
</style>
