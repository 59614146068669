<template>
  <div>
    <v-container>
      <base-header>
        <div slot="page-header">
          <h2 class="font-weight-black primary--text">Reports</h2>
        </div>
      </base-header>
    </v-container>
    <v-tabs v-model="tab" background-color="primary" centered dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        Users
        <v-icon>mdi-account</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        Raelics
        <v-icon>mdi-star</v-icon>
      </v-tab>

      <v-tab href="#tab-3">
        Comments
        <v-icon>mdi-account-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-12">
              <reports current-report-table="users"/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-12">
              <reports current-report-table="nft_tokens"/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-12">
              <reports current-report-table="comments"/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="showChangeUserRole" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Select the new user role for {{ user.userInfo.nickname }}
        </div>

        <v-select
            v-model="selectedRole"
            :items="[{id:'ADMIN', label:'Admin'},{id: 'USER', label:'User'}, {id:'MODERATOR', label:'Moderator'}]"
            item-text="label"
            item-value="id"
            label="Role"
        ></v-select>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showChangeUserRole=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="setUserRole()">
          Assign
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showBanUserConfirm" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to block {{ user.userInfo.nickname }}?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showBanUserConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="banUser()">
          BLOCK
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showPasswordResetConfirm" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to send reset password to {{ user.email }}?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showPasswordResetConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="sendResetPassword()">
          SEND
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showVerifyUserConfirm" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" color="blue-grey darken-3" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to set as verified {{ user.userInfo.nickname }}?
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showVerifyUserConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="success" plain @click="verifyUser()">
          Confirm
        </v-btn>
      </v-sheet>
    </v-dialog>
  </div>

</template>

<script>
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";
import BackButton from "@/components/Common/BackButton";
import Tokens from "@/views/tables/Tokens";
import Users from "@/views/tables/Users";
import Reports from "@/views/tables/Reports";

export default {
  name: "User",
  components: {
    BackButton,
    BaseHeader,
    Reports,
  },
  computed: {},
  data() {
    return {
      currentUserId: this.$store.state.current_user_id,
      tab: null,
    }
  }
}
</script>

<style scoped>

</style>
