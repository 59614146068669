var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"height":"60vh","headers":_vm.headers[_vm.currentReportTable],"loading":_vm.loading,"items":_vm.tokens,"page":_vm.pagination.page,"footer-props":{
                        'items-per-page-options':[5,10,20],
                        itemsPerPageText:''
                      },"server-items-length":_vm.pagination.totalItems,"items-per-page":_vm.pagination.rowsPerPage,"loading-text":"Loading... Please wait","item-key":"name"},on:{"update:items-per-page":_vm.itemsPerPageChange,"update:page":_vm.pageChange},scopedSlots:_vm._u([(_vm.currentReportTable==='users')?{key:"item.user.userInfo.nickname",fn:function(ref){
                      var item = ref.item;
return [_c('router-link',{attrs:{"to":'/users/'+item.user.id}},[_c('v-avatar',{staticClass:"ma-2",attrs:{"size":"45px"}},[_c('v-img',{attrs:{"src":item.user.userInfo.avatarUrl || require('../../assets/images/users/2.png')}})],1),_vm._v(_vm._s(item.user.userInfo.nickname)+" ")],1)]}}:null,(_vm.currentReportTable==='users')?{key:"item.requestedUser.userInfo.nickname",fn:function(ref){
                      var item = ref.item;
return [_c('router-link',{attrs:{"to":'/users/'+item.requestedUser.id}},[_c('v-avatar',{staticClass:"ma-2",attrs:{"size":"45px"}},[_c('v-img',{attrs:{"src":item.requestedUser.userInfo.avatarUrl || require('../../assets/images/users/2.png')}})],1),_vm._v(_vm._s(item.requestedUser.userInfo.nickname)+" ")],1)]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }